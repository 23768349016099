import { defineStore, acceptHMRUpdate } from 'pinia';

export const useBaseStore = defineStore('base', {
  state: () => ({
    user: {
      id: 0,
      username: '',
      role: '',
      fullname: '',
      email: '',
      theme: '',
    },
    company: { id: 0, CompanyName: '', isComplete: false, isActioned: false },
    appMode: null,
    progress: null,
    progressId: null,
    navigation: {
      startingIndex: 0,
      currentIndex: 0,
      numberOfClicks: 0,
    },
    showSidebarRight: false,
    completedBtnIsDisabled: true,
    activeTab: null,
    showScreenSizeOverlay: false,
    showSupportModal: false,
  }),
  getters: {
    getUser: state => state.user,
    getCompany: state => state.company,
    getAppMode: state => state.appMode,
  },
  actions: {
    setUser(user) {
      this.user = user;
    },
    setCompany(obj) {
      this.company = {
        id: obj.id,
        CompanyName: obj.CompanyName,
        isComplete: obj.isComplete,
      };
    },
    setAppMode(mode) {
      this.appMode = mode;
    },
    setProgress(progressId, progress) {
      this.progressId = progressId;
      this.progress = Object.assign(progress);
      delete this.progress.id;
    },
    setProgressId(progressId) {
      this.progressId = progressId;
    },
    updateProgress(key, value) {
      this.progress[key] = value;
    },
  },
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBaseStore, import.meta.hot));
}
